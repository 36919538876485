<template>
  <div style="width: 100%">
    <div
      v-if="isEdit"
      style="display: flex; justify-content: flex-end;"
    >
      <icon-edit
        class="crm-icon"
        @click="() => $emit('edit')"
      />
    </div>
    <div class="line-title">
      <div class="line-title-inner">
        Информация о юридическом лице
      </div>
    </div>

    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          Название Юр лица <span class="crm-error-text">*</span>
        </div>
        <b-input
          v-model.trim="$v.legalPersonData.name.$model"
          class="crm-form-field"
          placeholder="Введите название юр. лица"
          :state="checkValidation ? !$v.legalPersonData.name.$error: null"
        />
        <div
          v-if="!isDoctisNet"
          class="mt-4"
        >
          <b-form-checkbox
            v-model="legalPersonData.isOutsideOfRussia"
            size="sm"
          >
            <span class="text-secondary">
              outside of Russia
            </span>
          </b-form-checkbox>
        </div>
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          Сеть клиник <span class="crm-error-text">*</span>
        </div>
        <div class="crm-radio-buttons crm-radio-buttons_vertical">
          <b-form-radio
            v-model="networkType"
            name="some-radios"
            size="sm"
            value="none"
            @change="checkNonNetwork"
          >
            Не выбрана
          </b-form-radio>

          <b-form-radio
            v-model="networkType"
            name="some-radios"
            value="new"
            size="sm"
            @change="checkNewNetwork"
          >
            Создать новую
          </b-form-radio>

          <b-form-radio
            v-model="networkType"
            name="some-radios"
            value="exist"
            size="sm"
            @change="checkCurrentNetwork"
          >
            Выбрать существующую
          </b-form-radio>
        </div>
        <b-input
          v-if="networkType === 'new'"
          v-model.trim="$v.legalPersonData.networkTitle.$model"
          :state="checkValidation? !$v.legalPersonData.networkTitle.$error : null"
          class="crm-form-field"
          placeholder="Введите название сети"
        />

        <template v-if="networkType === 'exist'">
          <v-select
            v-model="$v.legalPersonData.networkId.$model"
            :reduce="network => network.id"
            :options="networks"
            placeholder="Выберите сеть"
            label="title"
            class="crm-form-field"
          />
          <div
            v-if="errorsValidation.network"
            class="crm-error-text"
          >
            {{ errorsValidation.network[0] }}
          </div>
        </template>
      </div>
    </div>

    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          ФИО руководителя <span class="crm-error-text">*</span>
        </div>
        <b-input
          v-model.trim="$v.legalPersonData.leaderFio.$model"
          :state="checkValidation ? !$v.legalPersonData.leaderFio.$error : null"
          required
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          Должность руководителя <span class="crm-error-text">*</span>
        </div>
        <b-input
          v-model.trim="$v.legalPersonData.leaderPosition.$model"
          :state="checkValidation ? !$v.legalPersonData.leaderPosition.$error : null"
          required
          class="crm-form-field"
        />
      </div>
    </div>
    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          Расчетный счет <span class="crm-error-text">*</span>
        </div>

        <b-input
          v-model.trim="$v.legalPersonData.checkingAccount.$model"
          :state="checkValidation ? !$v.legalPersonData.checkingAccount.$error : null"
          placeholder="Расчетный счет"
        />

        <div
          v-if="errorsValidation.checkingAccount"
          class="crm-error-text"
        >
          {{ errorsValidation.checkingAccount[0] }}
        </div>
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          Юридический адрес
        </div>

        <b-input
          v-model="legalPersonData.legalAddress"
          placeholder="Юридический адрес"
        />
      </div>
    </div>
    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          Веб-сайт
        </div>
        <b-input
          v-model="$v.legalPersonData.site.$model"
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          ОГРН <span class="crm-error-text">*</span>
        </div>
        <b-input
          v-model.trim="$v.legalPersonData.ogrn.$model"
          :state="stateOgrn"
          required
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          ИНН <span class="crm-error-text">*</span>
        </div>
        <b-input
          v-model.trim="$v.legalPersonData.inn.$model"
          class="crm-form-field"
          :state="stateInn"
          required
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          КПП <span class="crm-error-text">*</span>
        </div>
        <b-input
          v-model="$v.legalPersonData.kpp.$model"
          class="crm-form-field"
          :state="stateKpp"
          required
        />
      </div>
    </div>

    <template v-for="(contact, contactIndex) in $v.legalPersonData.contacts.$each.$iter">
      <legal-entity-contact
        v-if="!contact.isDeleted"
        :key="contact.id"
        v-model="legalPersonData.contacts[contactIndex]"
        :index="+contactIndex"
        :v="$v"
        @delete="deleteContactHandler"
      />
    </template>

    <div class="crm-row">
      <b-button
        variant="link"
        :type="$const.PRIMARY_BUTTON"
        class="add-btn text-decoration-none"
        @click="addContact"
      >
        <span class="add-btn-text">Добавить контакт</span>
      </b-button>
    </div>

    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Прикрепленные клиники
      </div>
    </div>
    <legal-entity-clinic
      v-for="(contact, clinicIndex) in legalPersonData.clinics"
      :key="`clinic-${clinicIndex}`"
      v-model="legalPersonData.clinics"
      :index="clinicIndex"
      @delete="deleteClinicHandler"
    />

    <div class="crm-row">
      <b-button
        variant="link"
        :type="$const.PRIMARY_BUTTON"
        class="add-btn text-decoration-none"
        @click="addClinic"
      >
        <span class="add-btn-text">Добавить клинику</span>
      </b-button>
    </div>

    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Учередительные документы
      </div>
    </div>

    <legal-entity-documents
      edit
      :documents="legalPersonData.documents"
      @removeDocument="removeDocument"
    />

    <div class="crm-row">
      <b-button
        variant="outline-primary"
        size="sm"
        class="add-file-button"
        :type="$const.PRIMARY_BUTTON"
        @click="onClickOpenFileModal"
      >
        <icon-cross
          class="crm-icon-cross white"
        />
        Добавить файл
      </b-button>
    </div>

    <div class="crm-wrapper-buttons">
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="onClickSave"
      >
        Сохранить
      </b-button>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import IconEdit from 'assets/images/edit_icon.svg';
import IconCross from 'assets/images/cross.svg';
import { required, numeric } from 'vuelidate/lib/validators';
import {
  validateInn,
  showMessage,
  validateOgrn, validateKpp,
} from '@/helpers/utils';
import { showValidationErrorMessage } from '@/helpers/messages';
import { APP_URL } from '@/app.config';

import LegalEntityContact from '@/components/LegalEntities/LegalEntityContact';
import LegalEntityClinic from '@/components/LegalEntities/LegalEntityClinic';
import LegalEntityDocuments from '@/components/LegalEntities/LegalEntityDocuments';

export default {
  name: 'LegalEntityEditor',
  components: {
    IconEdit,
    IconCross,
    LegalEntityContact,
    LegalEntityClinic,
    LegalEntityDocuments,
  },
  mixins: [mixinRoles],
  props: {
    initialData: {
      type: Object,
      default: () => null,
    },
    networks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkValidation: false,
      legalPersonData: {
        clinics: [],
        contacts: [],
        documents: [],
        id: 0,
        inn: null,
        kpp: null,
        leaderFio: null,
        leaderPosition: null,
        name: null,
        networkId: null,
        networkTitle: null,
        ogrn: null,
        site: null,
        isOutsideOfRussia: false,
        checkingAccount: null,
        legalAddress: null,
      },
      networkType: 'none',
    };
  },
  validations() {
    return {
      legalPersonData: {
        name: { required },
        leaderFio: { required },
        leaderPosition: { required },
        inn: { required, validateInn: !(this.legalPersonData.isOutsideOfRussia || this.isDoctisNet) ? validateInn : () => true },
        kpp: { required, validateKpp: !(this.legalPersonData.isOutsideOfRussia || this.isDoctisNet) ? validateKpp : () => true },
        ogrn: { required, validateOgrn: !(this.legalPersonData.isOutsideOfRussia || this.isDoctisNet) ? validateOgrn : () => true },
        checkingAccount: { numeric, required },
        site: {},
        networkId: { required: this.networkType === 'exist' ? required : () => true },
        networkTitle: { required: this.networkType === 'new' ? required : () => true },
        contacts: {
          $each: {
            position: {
              required,
            },
            fio: {
              required,
            },
            email: {
              required,
              isValid: (val) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(val),
            },
            phoneNumber: {
              required,
            },
          },
        },
      },
    };
  },
  computed: {
    isDoctisNet() {
      return APP_URL === 'admin.doctis.net';
    },
    stateOgrn() {
      if (!this.checkValidation) return null;

      return !this.$v.legalPersonData.ogrn.$error;
    },
    stateInn() {
      if (!this.checkValidation) return null;

      return !this.$v.legalPersonData.inn.$error;
    },
    stateKpp() {
      if (!this.checkValidation) return null;

      return !this.$v.legalPersonData.kpp.$error;
    },
    isEdit() {
      return !!this.initialData;
    },
    errorsValidation() {
      const errors = {};

      errors.legalPersonName = [];
      if (!this.$v.legalPersonData.name.required) {
        errors.legalPersonName.push('Поле не может быть пустым');
      }

      errors.network = [];
      if (this.networkType === 'new') {
        if (!this.$v.legalPersonData.networkTitle.required) {
          errors.network.push('Поле не может быть пустым');
        }
      } else if (this.networkType === 'exist' && !this.$v.legalPersonData.networkId.required) {
        errors.network.push('Поле не может быть пустым');
      }

      errors.leaderFio = [];
      if (!this.$v.legalPersonData.leaderFio.required) {
        errors.leaderFio.push('Поле не может быть пустым');
      }

      errors.leaderPosition = [];
      if (!this.$v.legalPersonData.leaderPosition.required) {
        errors.leaderPosition.push('Поле не может быть пустым');
      }

      errors.inn = [];
      if (!this.$v.legalPersonData.inn.required) {
        errors.inn.push('Поле не может быть пустым');
      }
      if (!this.$v.legalPersonData.inn.validateInn) {
        errors.inn.push('Проверьте правильность инн');
      }

      errors.checkingAccount = [];
      if (!this.$v.legalPersonData.checkingAccount.numeric) {
        errors.checkingAccount.push('Допускаются только числа');
      }

      errors.ogrn = [];
      if (!this.$v.legalPersonData.ogrn.required) {
        errors.ogrn.push('Поле не может быть пустым');
      }
      if (!this.$v.legalPersonData.ogrn.validateOgrn) {
        errors.ogrn.push('Проверьте правильность огрн');
      }

      errors.kpp = [];
      if (!this.$v.legalPersonData.kpp.required) {
        errors.kpp.push('Поле не может быть пустым');
      }
      if (!this.$v.legalPersonData.kpp.validateKpp) {
        errors.kpp.push('Проверьте правильность кпп');
      }

      return errors;
    },
  },
  async created() {
    if (this.isEdit) {
      this.legalPersonData = {
        ...this.initialData,
        contacts: this.initialData.contacts.filter((item) => !item.isDeleted),
      };
      this.networkType = this.initialData.networkId ? 'exist' : 'none';
    }
  },
  methods: {
    onClickOpenFileModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FileModal',
        props: {
          saveFileCallback: this.saveFileCallback,
          multiple: true,
        },
      });
    },
    saveFileCallback(newFilesArray) {
      const files = [];
      newFilesArray.map((fileObject) => {
        files.push({
          name: fileObject.name,
          fileId: fileObject.guid,
        });
        return null;
      });
      this.legalPersonData.documents = [...this.legalPersonData.documents, ...files];
    },
    removeDocument(index) {
      this.legalPersonData.documents.splice(index, 1);
    },
    checkNonNetwork() {
      this.legalPersonData.networkId = null;
      this.legalPersonData.networkTitle = null;
    },
    checkNewNetwork() {
      this.legalPersonData.networkId = null;
      this.legalPersonData.networkTitle = null;
    },
    checkCurrentNetwork() {
      this.legalPersonData.networkId = null;
      this.legalPersonData.networkTitle = null;
    },
    addContact() {
      this.legalPersonData.contacts.push({
        position: '',
        fio: '',
        email: '',
        phoneNumber: '',
      });
    },
    addClinic() {
      this.legalPersonData.clinics.push(null);
    },
    deleteContactHandler(delIndex) {
      this.legalPersonData.contacts = this.legalPersonData.contacts.filter((item, index) => index !== delIndex);
    },
    deleteClinicHandler(delIndex) {
      this.legalPersonData.clinics = this.legalPersonData.clinics.filter((item, index) => index !== delIndex);
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidation = true;

      if (this.$v.$error) {
        showValidationErrorMessage();
        return;
      }

      const action = !this.isEdit ? this.$types.LEGAL_ENTITIES_CREATE : this.$types.LEGAL_ENTITIES_UPDATE;
      const legalPersonToSave = {
        ...this.legalPersonData,
        networkId: this.legalPersonData.networkId || null,
        clinics: this.legalPersonData.clinics.filter((item) => item !== null),
        contacts: this.legalPersonData.contacts.filter((item) => item.position || item.phoneNumber || item.fio || item.email),
      };

      try {
        await this.$store.dispatch(action, legalPersonToSave);
        this.$router.push('/legal-entities');
      } catch (e) {
        showMessage({
          type: 'error',
          message: this.isEdit ? 'Не удалось обновить данные о Юр лице' : 'Не удалось создать Юр лицо',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }
  cursor: pointer;
  rect {
    stroke: #6E88F3;
  }
  path {
    fill: $blue;
  }
}
.line-title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 20px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
    display: inline-block;
  }
}
.crm-row {
  display: flex;
  margin-top: 20px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

::v-deep.crm-form-field {
  input {
    padding: 11px 10px 12px;
    resize: none;
  }
}

.crm-input-label {
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.crm-error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

.crm-wrapper-buttons {
  margin-top: 30px;
}

.crm-radio-buttons_vertical {
  flex-direction: column;
  margin-bottom: 15px;

  .crm-radio-button {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.crm-icon-cross {
  margin-right: 5px;
  path {
    fill: $blue;
  }
}
.add-btn:focus  {
  box-shadow: none
}
.add-btn-text {
  font-weight: 500;
}

.add-file-button:hover .white {
  path {
    fill: $white;
  }
}
</style>
