<template>
  <div style="width: 100%;">
    <div class="crm-header">
      <div class="crm-title">
        {{ legalPerson.name }}
      </div>
      <div>
        <icon-edit
          class="crm-icon"
          @click="() => $emit('edit')"
        />
      </div>
    </div>
    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Информация о юридическом лице
      </div>
    </div>

    <div class="crm-info-wrapper">
      <div>
        <div class="crm-info-label">
          Юридическое лицо
        </div>
        <div class="crm-info-text">
          {{ legalPerson.name }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          Cеть клиник
        </div>
        <div class="crm-info-text">
          {{ legalPerson.networkTitle }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          ФИО руководителя
        </div>
        <div class="crm-info-text">
          {{ legalPerson.leaderFio }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          Должность
        </div>
        <div class="crm-info-text">
          {{ legalPerson.leaderPosition }}
        </div>
      </div>
      <div class="site-info">
        <div class="crm-info-label">
          Веб-сайт
        </div>
        <div class="crm-info-text">
          <a
            :href="`//${legalPerson.site}`"
            target="_blank"
            class="site-link"
          >{{ legalPerson.site }}</a>
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          ОГРН
        </div>
        <div class="crm-info-text">
          {{ legalPerson.ogrn }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          ИНН
        </div>
        <div class="crm-info-text">
          {{ legalPerson.inn }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          КПП
        </div>
        <div class="crm-info-text">
          {{ legalPerson.kpp }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          isOutsideOfRussia
        </div>
        <div class="crm-info-text">
          {{ legalPerson.isOutsideOfRussia? 'Да':'Нет' }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          Расчетный счет
        </div>
        <div class="crm-info-text">
          {{ legalPerson.checkingAccount || '—' }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          Юридический адрес
        </div>
        <div class="crm-info-text">
          {{ legalPerson.legalAddress || '—' }}
        </div>
      </div>
      <div>
        <div class="crm-info-label">
          Удален
        </div>
        <div class="crm-info-text">
          {{ legalPerson.isDeleted ? 'Да':'Нет' }}
        </div>
      </div>
    </div>

    <template v-for="contact in legalPerson.contacts">
      <div
        v-if="!contact.isDeleted"
        :key="contact.id"
        class="contacts-wrapper"
      >
        <div>
          <div class="crm-info-label">
            Должность
          </div>
          <div class="crm-info-text">
            {{ contact.position }}
          </div>
        </div>
        <div>
          <div class="crm-info-label">
            ФИО
          </div>
          <div class="crm-info-text">
            {{ contact.fio }}
          </div>
        </div>
        <div>
          <div class="crm-info-label">
            E-mail
          </div>
          <div class="crm-info-text">
            {{ contact.email }}
          </div>
        </div>
        <div>
          <div class="crm-info-label">
            Номер телефона
          </div>
          <div class="crm-info-text">
            {{ contact.phoneNumber }}
          </div>
        </div>
      </div>
    </template>

    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Прикрепленные клиники
      </div>
    </div>
    <template v-if="legalPerson.clinics.length > 0">
      <div
        v-for="(clinic, index) in legalPerson.clinics"
        :key="`clinic-${index}`"
        class="crm-info-text clinic-item"
      >
        {{ clinic.name }}
      </div>
    </template>
    <template v-else>
      —
    </template>

    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Учередительные документы
      </div>
    </div>

    <legal-entity-documents
      v-if="legalPerson.documents.length > 0"
      :documents="legalPerson.documents"
    />
    <template v-else>
      —
    </template>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import IconEdit from 'assets/images/edit_icon.svg';
import LegalEntityDocuments from '@/components/LegalEntities/LegalEntityDocuments';

export default {
  name: 'LegalEntityDetails',
  components: {
    IconEdit,
    LegalEntityDocuments,
  },
  mixins: [mixinRoles],
  props: {
    legalPerson: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

.crm-header {
  justify-content: space-between;
  display: flex;
}

.crm-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $black-light;
}

.line-title {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 15px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
    display: inline-block;
  }
}

.crm-info-text {
  color: $black-light;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.clinic-item {
  margin-bottom: 16px;
}

.crm-info-wrapper, .contacts-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 30px;
}

.contacts-wrapper {
  margin-top: 30px;
}

.crm-info-label {
  color: $gray-dark;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
}

.site-link {
  color: $blue;
}
.site-info{
  word-break: break-word;
}
</style>
