<template>
  <div
    class="crm-row"
    style="width: 100%"
  >
    <!-- <pre>{{ v.legalPersonData.contacts.$each }}</pre> -->
    <div class="crm-column">
      <div class="crm-input-label">
        Должность <span class="crm-error-text">*</span>
      </div>
      <b-input
        v-model="value.position"
        :state="v.$anyError ? !v.legalPersonData.contacts.$each[index].position.$error : null"
        class="crm-form-field"
        placeholder="Введите название"
      />
    </div>

    <!-- !$v.subprogram.usefulLinks.$each[index].link.required -->
    <div class="crm-column">
      <div class="crm-input-label">
        ФИО <span class="crm-error-text">*</span>
      </div>
      <b-input
        v-model="value.fio"
        :state="v.$anyError ? !v.legalPersonData.contacts.$each[index].fio.$error : null"
        class="crm-form-field"
        placeholder="Введите ФИО"
      />
    </div>
    <div class="crm-column">
      <div class="crm-input-label">
        E-mail <span class="crm-error-text">*</span>
      </div>
      <b-input
        v-model="value.email"
        :state="v.$anyError ? !v.legalPersonData.contacts.$each[index].email.$error : null"
        class="crm-form-field"
        placeholder="Введите почту"
      />
    </div>
    <div
      class="crm-column"
      style="display: flex; align-items: center;"
    >
      <div>
        <div class="crm-input-label">
          Номер телефона <span class="crm-error-text">*</span>
        </div>
        <b-form-input
          v-model="value.phoneNumber"
          v-mask="'+#(###)-###-##-##'"
          :state="v.$anyError ? !v.legalPersonData.contacts.$each[index].phoneNumber.$error : null"
          class="crm-form-field"
          placeholder="Введите номер"
        />
      </div>
      <icon-delete
        class="delete-icon"
        @click.stop="() => $emit('delete', index)"
      />
    </div>
  </div>
</template>

<script>

import IconDelete from 'assets/images/delete_icon.svg';

export default {
  name: 'LegalEntityContact',
  components: {
    IconDelete,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      default: () => null,
    },
    v: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

::v-deep.crm-form-field {
  input {
    padding: 11px 10px 12px;
    resize: none;
  }
}

.crm-input-label {
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.delete-icon {
  margin-left: 10px;
  position: relative;
  top: 12px;
  cursor: pointer;
}
.crm-error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
</style>
