<template>
  <div class="d-flex overflow-hidden">
    <b-icon icon="file-earmark-medical" />

    <div class="ml-1 text-nowrap">
      {{ document.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegalEntityDocumentItem',
  props: {
    document: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
